import React from 'react';
import ReactMarkdown from 'react-markdown';
import { stackStyled, Typography } from '../../../../theme';
import { StackOnThemeT, useStackStyletron } from '../../../../theme/StacksOnTheme';

interface IStepsForForm {
  steps: string;
  note: string;
}

const AnchorTxt = stackStyled(
  'a',
  ({ $theme } : { $theme?: StackOnThemeT }) => ({
    color: $theme?.stackOnColors.primaryGreen,
    textDecoration: 'underline',
    fontWeight: 400,
    ':hover': {
      textDecoration:'none',
    },
  })
);

const AnchorCode = stackStyled(
  'a',
  ({ $theme } : { $theme?: StackOnThemeT }) => ({
    color: $theme?.stackOnColors.primaryBlack,
    textDecoration: 'underline',
    fontWeight: 700,
  })
);

const NotesContainer = stackStyled(
  'div',
  ({ $theme }: { $theme?: StackOnThemeT }) => ({
    backgroundColor: $theme?.stackOnColors.backgroundYellow,
    padding: '14px 18px 14px 18px',
    margin: '40px 0 28px 0',
    borderRadius: '8px',
    
  })
);
const MainContainer = stackStyled("div", ({isMobile} : {isMobile}) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "left",
  marginTop:  isMobile ?  '15px' : "55px",
}));

const StepsForForm = (props: IStepsForForm) => {
  const [css, theme] = useStackStyletron();
  const [ isMobile, isTablet] = useStackStyletron();

  return (
    <MainContainer role='region' aria-label='Steps for Keys replacement request' isMobile={isMobile}>
      <ReactMarkdown
        components={{
          body: ({ node, ...props}) => (
            <Typography
              $fontFamily={"Montserrat"}
              $weight={400}
              $tagType={"body"}
              $color={theme.stackOnColors.primaryBlack}
              $textAlign={"left"}
              $margin={"0"}
              {...props}
            />
          ),
          p: ({ node, ...props }) => (
            <Typography
              $fontFamily={"Montserrat"}
              $weight={400}
              $tagType={"body"}
              $color={theme.stackOnColors.primaryBlack}
              $textAlign={"left"}
              $margin={0}
              $paddingBottom='15px'
              {...props}
            />
          ),
          strong: ({ node, ...props }) => (
            <Typography
              $fontFamily={"Montserrat"}
              $weight={700}
              $tagType={"label"}
              $color={theme.stackOnColors.primaryGreen}
              $textAlign={"left"}
              $margin={0}
              $paddingBottom='0'
              {...props}
            />
          ),
          a: ({ node, ...props }) => <AnchorTxt {...props} />,
        }}
      >
        {props.steps}
      </ReactMarkdown>
      {props.note != "/" ? (
        <NotesContainer>
          <Typography
            $fontFamily={"Montserrat"}
            $weight={400}
            $tagType={"body"}
            $color={theme.stackOnColors.primaryYellowDark}
            $textAlign={"left"}
          >
            {props.note}
          </Typography>
        </NotesContainer>
      ) : (
        <p></p>
      )}
      <Typography
        $fontFamily={"Montserrat"}
        $weight={400}
        $tagType={"body"}
        $color={theme.stackOnColors.primaryBlack}
        $textAlign={"left"}
        $margin={'0'}

      >
        California Residents must adhere to{" "}
        <AnchorCode
          href="https://leginfo.legislature.ca.gov/faces/codes_displaySection.xhtml?lawCode=CIV&sectionNum=1189"
          target={"_blank"}
        >
          Civil Code 1189
        </AnchorCode>
      </Typography>
    </MainContainer>
  );
};

export default StepsForForm;